.regionen-v1 {
	margin-top: var(--page-margin-top);
}

.regions-slide {
	.owl-stage {
		display: flex;


		.regions-content,
		.region-item,
		.regions-content-inner {
			height: 100%;
		}

		.owl-item {
			display: flex;
			flex: 1 0 auto;

		}
	}

	.region-item {
		border: 1px solid var(--color-grey-normal);

		img {
			border: 0;
			border-radius: 0;
			object-fit: cover;
			padding: 0;
			height: 250px;
		}

		h4 {
			font-size: 1.3rem;
		}

		.region-info {
			padding: 20px;
		}
	}
}