.parallax-v2 {
	position: relative;
	background-image: url(RESOURCE/img/sonnenuntergang_bodensee.jpg);
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	.btn-primary:hover {
		background-color: #fff;
		background-color: var(--color-white);
		color: #0c8e85;
		color: var(--color-secondary);
	}

	.parallax-box {
		background-color: var(--color-secondary);
		padding: 20px 20px 40px 20px;
		min-height: 400px;

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-white);
		}

		.btn {
			float: right;
			font-weight: 700;
			font-size: 18px;
			position: absolute;
			right: 30px;
			bottom: 25px;

			@media (max-width:1199px) {
				position: unset;
			}
		}
	}

}