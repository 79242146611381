@media print {

	*,
	*:before,
	enter code here *:after {
		color: #000 !important;
		text-shadow: none !important;
		background: transparent !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		margin: 0;
		padding: 0
	}

	.print-head {
		text-align: center;
		padding: 15px;
		color: #12355b;
		font-weight: bold;
		font-size: 20px;
	}

	.agb-v1 {
		margin-top: 0 !important;
	}

	.my-5 {
		margin: 0 !important;
	}


	body {
		margin: 20mm;
	}



	a,
	a:visited {
		text-decoration: underline
	}

	a[href]:after {
		content: " ("attr(href) ")"
	}

	abbr[title]:after {
		content: " ("attr(title) ")"
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: ""
	}


	h1 {
		font-size: 36px !important;
	}

	p {
		font-size: 20px !important;
	}

	h3 {
		font-size: 16px
	}

	.unit-room-details-v1 .room-details ul {
		list-style-type: disc;
		padding: 0;
	}

	a[href]:after {
		content: none !important;
		display: none;
		visibility: hidden;
	}

	.table td,
	.table th {
		padding: 0.25rem !important;
	}



	.hidden-print {
		display: none;
		visibility: hidden
	}




	.visible-print {
		display: block !important;
		visibility: visible;
	}

	.properties .col-sm-4 .tick-list li {
		width: 40%;
	}


	.print-gap {
		height: 100px;
	}

	.page-header label {
		display: none;
	}


	.mt30 {
		margin-top: 50px
	}

	.unit-view {
		padding-top: 10px
	}

	.page-brake {
		page-break-after: always;
	}

	.text-blue {
		color: #0060a1 !important
	}

	.txt-orange {
		color: #fa590c !important
	}

	.text-grey {
		color: #797979 !important
	}
}