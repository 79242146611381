.about-us-v1 {
	margin-top: var(--page-margin-top);

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */
	.about-bl {
		img {
			width: 100%;
		}
	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);
		min-height: 90px !important;

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}



	.bodensee-region-slide {
		.picture {
			padding: 0 10px;

			img {
				opacity: 1 !important;
			}
		}

		.owl-nav {
			position: absolute;
			top: 35%;
			left: 0;
			width: 100%;

			@media (max-width:992px) {
				display: none;
			}


			button {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				border: 1px solid #ddd !important;
				border-radius: 50%;
				box-shadow: 0 0 10px rgba(0, 0, 0, .5);
				background-color: var(--color-white);
				position: absolute;
				left: 20px;
				font-size: 34px;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					display: inline-block;
					font-style: normal;
					font-feature-settings: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;
					line-height: 6px;

					&:before {
						color: var(--color-black);
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						position: relative;
						left: 3px;
						font-size: 15px;
						line-height: 18px;
					}
				}

				&:hover {
					span {
						opacity: .3;
					}
				}

				&.owl-next {
					position: absolute;
					right: 20px;
					left: initial;

					span {

						&:before {
							content: "\f054";
							left: 8px;
						}
					}
				}

				&.owl-prev {
					span {

						&:before {
							content: "\f053";

						}
					}
				}
			}
		}
	}

}