.faq-v2 {
	margin-top: var(--page-margin-top);

	.section-title {
		font-size: 1.4rem;

		@media(max-width: 767px) {
			font-size: 1.1rem;
		}
	}

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}

		.card-body {

			ul {
				display: block;

				margin-block-start: 1em;
				margin-block-end: 1em;
				margin-inline-start: 0;
				margin-inline-end: 0;
				padding-inline-start: 40px;
				list-style-type: disc;

				li {
					display: list-item;
				}
			}
		}

	}



	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

	i {
		margin-right: 7px;
	}

}