.static-view {
	margin-top: var(--page-margin-top);

	&.vermieter-v1,
	&.about-us-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/zeppelin_bodensee-a27fe9be.jpg);
		}
	}

	&.multifewo-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/multifewo_banner.jpg);
		}
	}
}