.index-slide-show-v1 {
	position: relative;
	/*height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);*/
	height: calc(100vh - 300px);
	max-height: calc(100vh - 30vh);
	margin-top: 120px;
	/*overflow: hidden;*/

	@media (max-width: 1199px) {
		margin-top: 99px;
		max-height: calc(100vh - 300px);
	}

	@media (max-width: 992px) {
		min-height: 420px;
		height: calc(100vh - 99px);

	}


	/*	@media (min-width: 769px) {
		height: calc(100vh - 300px);
		max-height: calc(100vh - 300px);
		margin-top: 100px;
	}

	@media (min-width: 1201px) {
		height: calc(100vh - 300px);
		max-height: calc(100vh - 300px);
		margin-top: 149px;
	}*/

	@media (max-height: 600px) {
		min-height: 550px;

	}

	.owl-carousel {
		z-index: unset;
	}


	.index-slide {
		max-height: calc(100vh - 30vh);
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);


		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: 60vh;
			min-height: 60vh;
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			/*	height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);*/

			height: var(--index-slide-show-height);

			max-height: calc(100vh - 30vh);

			@media (max-width:1199px) {
				max-height: calc(100vh - 300px);
			}

			@media (max-height: 600px) {
				min-height: 550px;

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				max-height: calc(100vh - 300px);

				@media (max-width: 1199px) {
					max-height: var(--index-slide-show-height);

				}

				@media (max-width: 767px) {
					/*	height: calc(100vh - 280px);*/

					max-height: var(--index-slide-show-height);
				}

				/*@media (min-width: 769px) {
					max-height: calc(100vh - 100px);
				}

				@media (min-width: 1201px) {
					max-height: calc(100vh - 300px);
				}*/
			}
		}

		.owl-nav {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
			z-index: 1;

			@media (max-width:992px) {
				display: none;
			}


			button {
				width: 15px;
				height: 50px;
				border-radius: 50%;
				/*border: 2px solid #fff !important;
				border-radius: 50%;
				box-shadow: 0 0 10px rgba(0, 0, 0, .5);
				background-color: var(--color-white);*/
				position: absolute;
				left: 20px;
				font-size: 34px;
				display: flex;
				align-items: center;
				justify-content: center;



				span {
					display: inline-block;
					font-style: normal;
					font-feature-settings: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;
					line-height: 6px;

					&:before {
						color: var(--color-white);
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						position: relative;
						left: 3px;
						font-size: 25px;
						line-height: 18px;
					}
				}

				&:hover {
					span {
						opacity: 1;
					}
				}

				&.owl-next {
					position: absolute;
					right: 10px;
					left: initial;

					span {

						&:before {
							content: "\f054";
							left: 8px;
						}
					}
				}

				&.owl-prev {
					left: 10px;

					span {

						&:before {
							content: "\f053";

						}
					}
				}
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 29vh;
		font-family: 'Poppins', cursive;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-width: 767px) {
			top: 40vh;

		}

		@media (max-height: 600px) {
			top: 200px;

		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 5vw;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 80px;
				}

				@media (max-width: 1140px) {
					font-size: 57px;
				}

				@media (max-width: 992px) {
					font-size: 4rem;
					line-height: 4rem;
				}

				@media (max-width: 576px) {
					font-size: 35px;
					line-height: 40px;
				}

				@media (max-height: 600px) {
					font-size: 2.75rem;
					line-height: 2.75rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.5rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				@media (min-width: 1600px) {
					font-size: 2.5rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 2.0rem;
				}

				@media (max-width: 575px) {
					font-size: 1.5rem;
				}
			}
		}

	}
}